<template>
    <div>
        <v-container
                class="white mt-5 pl-6 d-flex justify-space-between align-center"
        >
            <h3 class="primetime">Export Vehicles</h3>
            <!--<v-btn text color="grey"><v-icon>mdi-pencil</v-icon></v-btn>-->
        </v-container>
        <v-container class="px-0">
            <v-data-table
                    :headers="headers"
                    :items="vehicles"
                    hide-default-footer
            >
                <template v-slot:item.id="{ item }">
                    <div>

                        <v-btn text :to="`/vehicles/${item.id}`">
                            <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>

                    </div>
                </template>

            </v-data-table>
        </v-container>
    </div>
</template>

<script>
    export default {
        props: ['vehicles', 'yardShow'],
        data() {
            return {
                headers: [
                    {
                        text: "Year",
                        align: "start",
                        value: "year",
                    },
                    {text: "Make", value: "make"},
                    {text: "Model", value: "model"},
                    {text: "Color", value: "color"},
                    {text: "VIN", value: "vin"},
                    {text: "Status", value: "status_name"},
                    {text: "Title No", value: "title_number"},
                    {text: "Title State", value: "location"},
                    {text: "LOT No", value: "lot_number"},
                    {text: "Yard", value: "yard_name"},
                    {text: "View", value: "id"},
                ],
            }
        },
        mounted() {
          console.log(this.yardShow);
          if(!this.yardShow) {
            this.headers = this.headers.filter((item) => item.text !== 'Yard')
          }
        }
    }
</script>

<style></style>
