<template>
  <v-row class="">
    <v-dialog v-model="dialog" max-width="1300px" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="inventory"
          small
          elevation="0"
          v-bind="attrs"
          v-on="on"
          class="button-short text-capitalize d-block mt-3 ml-3 mr-3"
          ><v-icon left color="gray">mdi-file-document</v-icon>Inventory
          Report</v-btn
        >

        <!--                <v-btn
                    v-if="inventory"
                    v-bind="attrs"
                    v-on="on"
                    class="custom-btn2 button-short"
                    elevation="0"><v-icon left color="gray">mdi-file-document</v-icon>Inventory Report</v-btn>-->

        <v-btn
          v-else
          small
          v-bind="attrs"
          v-on="on"
          class="custom-btn2 button-short"
        >
          <v-icon left color="gray">mdi-file-document</v-icon>

          Report</v-btn
        >
      </template>
      <v-card>
        <v-card-title class="mb-1" style="border-bottom: 1px solid #eeee">
          <span class="headline ml-11"> Report</span>
          <v-btn
            class="ml-auto"
            color="blue darken-1"
            text
            @click="dialog = !dialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-btn
            :disabled="!tableData.length"
            @click="openAsPdf"
            class="ml-11 mr-4"
            color="primary"
            :loading="downloadingPdf"
          >
            Open as pdf
          </v-btn>
          <v-btn
            :disabled="!tableData.length"
            @click="downloadExcel"
            class="ml-auto"
            color="primary"
            :loading="downloadingXel"
          >
            Download as Excel
          </v-btn>
          <div v-if="!tableData.length && !loading">
            <h1 class="text-center py-6">No data found</h1>
          </div>
          <vue-content-loading :width="300" :height="100" v-if="loading">
            <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
            <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
          </vue-content-loading>

          <div class="container" v-for="(t, i) in tableData" :key="i" v-else>
            <table
              style="width: 100%; margin-bottom: 5px; border-collapse: collapse"
            >
              <thead>
                <tr>
                  <th class="text-left" style="padding: 2px 10px">Inventory</th>
                  <th class="text-left" style="padding: 2px 10px">
                    {{ t.heading.inventory }}
                  </th>
                  <th class="text-left" style="padding: 2px 10px">Sort Type</th>
                  <!-- <th class="text-left"></th> -->
                  <th class="text-left" style="padding: 2px 10px">
                    {{ t.heading.sort_type }}
                  </th>
                  <th class="text-left" style="padding: 2px 10px">Location</th>
                  <!-- <th class="text-left"></th> -->
                  <th class="text-left" style="padding: 2px 10px">
                    {{ t.heading.location }}
                  </th>
                </tr>
              </thead>
            </table>

            <div class="reportTable">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th class="text-left">HAT NO</th>
                    <th class="text-left">DATE RECEIVED</th>
                    <th class="text-left">YEAR</th>
                    <th class="text-left">MAKE</th>
                    <th class="text-left">MODEL</th>
                    <th class="text-left">COLOR</th>
                    <th class="text-left">VIN</th>
                    <th class="text-left">TITLE</th>
                    <th class="text-left">TITLE TYPE</th>
                    <th class="text-left">KEYS</th>
                    <th class="text-left">AGE</th>
                    <th class="text-left">STATUS</th>
                    <th class="text-left">PRICE</th>
                    <th class="text-left">LOCATION</th>
                    <th class="text-left">HYBRID</th>
                    <th class="text-left" v-if="roleId == 0 || roleId == 1 || roleId == 6 || roleId == 2 || roleId == 4 || roleId == 5">YARD</th>
                    <th class="text-left">NOTE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(tableData, k) in t.vehicles" :key="k">
                    <td>{{ tableData.hat_no }}</td>
                    <td>{{ tableData.data_received }}</td>
                    <td>{{ tableData.year }}</td>
                    <td>{{ tableData.make }}</td>
                    <td>{{ tableData.model }}</td>
                    <td>{{ tableData.color }}</td>
                    <td>{{ tableData.vin }}</td>
                    <td>{{ tableData.title }}</td>
                    <td>{{ tableData.title_type }}</td>
                    <td>{{ tableData.keys }}</td>
                    <td>{{ tableData.age }}</td>
                    <td>{{ tableData.status }}</td>
                    <td>{{ tableData.price }}</td>
                    <td>{{ tableData.location }}</td>
                    <td>{{ tableData.hybrid }}</td>
                    <td v-if="roleId == 0 || roleId == 1 || roleId == 6 || roleId == 2 || roleId == 4 || roleId == 5">{{ tableData.yard }}</td>
                    <td>{{ tableData.note }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
// import {tableData} from './data'
import { VueContentLoading } from "vue-content-loading";
import downloadPdf from "@/helpers/downloadPdf";
import downloadFile from "@/helpers/downloadFile";
export default {
  props: ["show", "location", "status", "user", "inventory"],
  name: "Manage2",
  components: {
    VueContentLoading,
  },
  data() {
    return {
      dataId: this.id,
      roleId: JSON.parse(localStorage.getItem("user")).role,
      editing: false,
      tableData: [],
      dialog: false,
      allParams: "",
      loading: false,
      baseUrl: "",
      userId: JSON.parse(localStorage.getItem("user")).id,
      downloadingPdf : false,
      downloadingXel : false,
    };
  },
  methods: {
    loadingManage (value) {
      this.downloadingPdf = value;
    },
    xelLoadingManage (value) {
      this.downloadingXel = value;
    },
    openAsPdf() {
      window.open(
          `${this.baseUrl}/api/v1/reports/inventory-report/pdf?auth_user_id=${this.userId}&${this.allParams}`,
          "_blank"
      );
      // this.loadingManage(true);
      //  const url = `${this.baseUrl}/api/v1/reports/inventory-report/pdf?auth_user_id=${this.userId}&${this.allParams}`;
      //  downloadPdf(url, this.loadingManage)
    },
    downloadExcel() {
      // window.open(
      //   `${this.baseUrl}/api/v1/reports/inventory-report/export-excel?auth_user_id=${this.userId}&${this.allParams}`,
      //   "_blank"
      // );
      this.xelLoadingManage(true);
      const url = `${this.baseUrl}/api/v1/reports/inventory-report/export-excel?auth_user_id=${this.userId}&${this.allParams}`;
      downloadFile(url, 'inventory_report', '.xls', this.xelLoadingManage)
    },
    cancel() {
      this.dataId = false;
      this.editing = false;
      this.dialog = false;
      this.$emit("cancelForm");
    },
    getData() {
      this.loading = true;
      let url;
      if (this.location && !this.user && !this.inventory) {
        url = `location=${this.location}&status=${this.status}`;
      } else if (this.user && !this.location && !this.inventory) {
        url = `status=${this.status}&customer_user_id=${this.user}`;
      } else if (this.user && this.location && !this.inventory) {
        url = `location=${this.location}&status=${this.status}&customer_user_id=${this.user}`;
      } else if (
        this.status &&
        !this.user &&
        !this.location &&
        !this.inventory
      ) {
        url = `status=${this.status}`;
      } else if (this.inventory && this.user) {
        url = `customer_user_id=${this.user}`;
      } else {
        Api.get(`/reports/inventory-report`)
          .then((res) => {
            this.tableData = res.data;
            this.loading = false;
          })
          .catch((err) => {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          });
        return;
      }
      this.allParams = url;
      Api.get(`/reports/inventory-report?${url}`)
        .then((res) => {
          this.tableData = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
        });
    },
  },
  created() {
    this.baseUrl = process.env.VUE_APP_API_ENDPOINT;
  },
  watch: {
    dialog: function (newVal, oldVal) {
      if (newVal) {
        this.getData();
      }
    },
  },
  mounted() {
    console.log(this.roleId);
    if (this.roleId === 3) {
      this.user = JSON.parse(localStorage.getItem("user")).id;
    }
  },
};
</script>
<style scoped>
button.custom-btn2.v-btn.v-btn--contained.v-btn--is-elevated.v-btn--has-bg.theme--light.elevation-0.v-size--default {
  padding: 0 12.4444444444px;
  margin: 10px;
  width: 93%;
  font-size: 11px;
}
button.custom-btn2.v-btn.v-btn--contained.v-btn--is-elevated.v-btn--has-bg.theme--light.elevation-0.v-size--default.button-short {
  background-color: white !important;
  border-color: white;
  padding: 5px 24px 5px 20px;
  width: 90%;
}

.reportTable table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.reportTable table th,
td {
  padding: 2px 4px;
}
</style>
